import { useCallback } from 'react';
import { saveAs } from 'file-saver';

const useClickTrackExport = (piece, startLabel) => {
  const generateClickTrack = useCallback((format) => {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const startIndex = piece.sections.findIndex(section => section.label === startLabel);
      let currentTime = 0;

      // Calculate total duration
      const totalDuration = piece.sections.slice(startIndex).reduce((acc, section) => {
        const beatsPerBar = section.timeSignature.numerator;
        const beatDuration = 60 / (section.tempo || section.endTempo) * (4 / section.timeSignature.denominator);
        return acc + section.duration * beatsPerBar * beatDuration;
      }, 0);

      const offlineContext = new OfflineAudioContext(2, audioContext.sampleRate * totalDuration, audioContext.sampleRate);

      piece.sections.slice(startIndex).forEach(section => {
        const beatsPerBar = section.timeSignature.numerator;
        const beatDuration = 60 / (section.tempo || section.endTempo) * (4 / section.timeSignature.denominator);

        for (let bar = 0; bar < section.duration; bar++) {
          for (let beat = 0; beat < beatsPerBar; beat++) {
            const oscillator = offlineContext.createOscillator();
            const gainNode = offlineContext.createGain();

            oscillator.type = 'square';
            oscillator.frequency.setValueAtTime(beat === 0 ? 440 : 220, currentTime);

            gainNode.gain.setValueAtTime(0, currentTime);
            gainNode.gain.linearRampToValueAtTime(1, currentTime + 0.005);
            gainNode.gain.linearRampToValueAtTime(0, currentTime + 0.05);

            oscillator.connect(gainNode);
            gainNode.connect(offlineContext.destination);

            oscillator.start(currentTime);
            oscillator.stop(currentTime + 0.05);

            currentTime += beatDuration;
          }
        }
      });

      offlineContext.startRendering().then((renderedBuffer) => {
        if (format === 'wav') {
          const wavData = bufferToWave(renderedBuffer, renderedBuffer.length);
          const blob = new Blob([wavData], { type: 'audio/wav' });
          resolve(blob);
        } else if (format === 'mp3') {
          import('@breezystack/lamejs').then(({ Mp3Encoder }) => {
            console.log('Rendered buffer:', renderedBuffer.length, 'samples,', renderedBuffer.numberOfChannels, 'channels');
            const channels = renderedBuffer.numberOfChannels;
            
            const sampleRate = renderedBuffer.sampleRate;
            const mp3encoder = new Mp3Encoder(channels, sampleRate, 128);
            const mp3Data = [];

            const left = renderedBuffer.getChannelData(0).map(x => x * 32767);
const right = channels > 1 ? renderedBuffer.getChannelData(1).map(x => x * 32767) : left;
            const sampleBlockSize = 1152;
            const numSamples = renderedBuffer.length;
            const maxSamples = Math.floor(numSamples / sampleBlockSize) * sampleBlockSize;

            for (let i = 0; i < maxSamples; i += sampleBlockSize) {
              const leftChunk = left.subarray(i, i + sampleBlockSize);
              const rightChunk = right.subarray(i, i + sampleBlockSize);
              
              const mp3buf = mp3encoder.encodeBuffer(leftChunk, rightChunk);
              if (mp3buf.length > 0) {
                mp3Data.push(new Int8Array(mp3buf));
              }
            }

            const mp3buf = mp3encoder.flush();
            if (mp3buf.length > 0) {
              mp3Data.push(new Uint8Array(mp3buf));
            }

            const blob = new Blob(mp3Data, { type: 'audio/mp3' });
            resolve(blob);
          });
        } else {
          reject(new Error('Unsupported format'));
        }
      }).catch(reject);
    });
  }, [piece, startLabel]);

  const exportClickTrack = useCallback((format) => {
    generateClickTrack(format).then((blob) => {
      saveAs(blob, `click_track.${format}`);
    }).catch((error) => {
      console.error('Failed to generate click track:', error);
    });
  }, [generateClickTrack]);

  return { exportClickTrack };
};

// Helper function to convert AudioBuffer to WAV format
function bufferToWave(abuffer, len) {
  const numOfChan = abuffer.numberOfChannels;
  const length = len * numOfChan * 2 + 44;
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);
  const channels = [];
  let sample;
  let offset = 0;
  let pos = 0;

  // write WAVE header
  setUint32(0x46464952);
  setUint32(length - 8);
  setUint32(0x45564157);
  setUint32(0x20746d66);
  setUint32(16);
  setUint16(1);
  setUint16(numOfChan);
  setUint32(abuffer.sampleRate);
  setUint32(abuffer.sampleRate * 2 * numOfChan);
  setUint16(numOfChan * 2);
  setUint16(16);
  setUint32(0x61746164);
  setUint32(length - pos - 4);

  // write interleaved data
  for (let i = 0; i < abuffer.numberOfChannels; i++)
    channels.push(abuffer.getChannelData(i));

  while (pos < length) {
    for (let i = 0; i < numOfChan; i++) {
      sample = Math.max(-1, Math.min(1, channels[i][offset]));
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;
      view.setInt16(pos, sample, true);
      pos += 2;
    }
    offset++;
  }

  return buffer;

  function setUint16(data) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data) {
    view.setUint32(pos, data, true);
    pos += 4;
  }
}

export default useClickTrackExport;