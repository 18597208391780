import React from 'react';
import { Box, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Text } from '@chakra-ui/react';

const VolumeSlider = ({ volume, onChange }) => {
  return (
    <Box>
      <Text mb={2}>Volume</Text>
      <Slider
        aria-label="volume-slider"
        defaultValue={volume * 100}
        min={0}
        max={100}
        onChange={(value) => onChange(value / 100)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Box>
  );
};

export default VolumeSlider;