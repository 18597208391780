import React, { useState, useEffect } from 'react';
import { Box, Select, Button, VStack, Heading, HStack } from '@chakra-ui/react';
import useMetronome from '../hooks/useMetronome';
import useClickTrackExport from '../hooks/useClickTrackExport';
import VolumeSlider from './VolumeSlider';
import TempoSlider from './TempoSlider';

export default function Metronome() {
  const [pieces, setPieces] = useState([]);
  const [composers, setComposers] = useState([]);
  const [selectedComposer, setSelectedComposer] = useState('');
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [startLabel, setStartLabel] = useState('');
const { 
    isPlaying, 
    toggleMetronome, 
    setMetronomeVolume, 
    volume, 
    tempoPercentage, 
    setMetronomeTempoPercentage 
  } = useMetronome(selectedPiece, startLabel);
  const { exportClickTrack } = useClickTrackExport(selectedPiece, startLabel);
  useEffect(() => {
    fetchPieces();
  }, []);

  const fetchPieces = async () => {
    try {
      const response = await fetch('https://backend.mail-c69.workers.dev/api/pieces');
      const data = await response.json();
      setPieces(data.pieces);
      
      // Extract unique composers
      const uniqueComposers = [...new Set(data.pieces.map(piece => piece.composer))];
      setComposers(uniqueComposers);
    } catch (error) {
      console.error('Error fetching pieces:', error);
      setPieces([]);
      setComposers([]);
    }
  };

  const handleComposerChange = (event) => {
    setSelectedComposer(event.target.value);
    setSelectedPiece(null);
    setStartLabel('');
  };

  const handlePieceChange = (event) => {
    const selected = pieces.find(piece => piece.id === event.target.value);
    setSelectedPiece(selected);
    setStartLabel(selected?.sections[0]?.label || '');
  };

  const handleStartLabelChange = (event) => {
    setStartLabel(event.target.value);
  };

  const filteredPieces = selectedComposer
    ? pieces.filter(piece => piece.composer === selectedComposer)
    : [];

  return (
    <Box p={4}>
      <VStack spacing={4}>
        <Heading>Metronome</Heading>
        <Select placeholder="Select composer" onChange={handleComposerChange} value={selectedComposer}>
          {composers.map((composer) => (
            <option key={composer} value={composer}>
              {composer}
            </option>
          ))}
        </Select>
        <Select 
          placeholder="Select piece" 
          onChange={handlePieceChange} 
          value={selectedPiece?.id || ''}
          isDisabled={!selectedComposer}
        >
          {filteredPieces.map((piece) => (
            <option key={piece.id} value={piece.id}>
              {piece.name}
            </option>
          ))}
        </Select>
        <Select 
          placeholder="Start label" 
          onChange={handleStartLabelChange} 
          value={startLabel}
          isDisabled={!selectedPiece}
        >
          {selectedPiece?.sections.map((section) => (
            <option key={section.label} value={section.label}>
              {section.label}
            </option>
          ))}
        </Select>
        <VolumeSlider volume={volume} onChange={setMetronomeVolume} />
        <TempoSlider tempoPercentage={tempoPercentage} onChange={setMetronomeTempoPercentage} />
        <Button onClick={toggleMetronome} colorScheme={isPlaying ? 'red' : 'green'} isDisabled={!selectedPiece}>
          {isPlaying ? 'Stop' : 'Start'}
        </Button>
        <HStack>
          <Button onClick={() => exportClickTrack('wav')} isDisabled={!selectedPiece}>
            Export WAV
          </Button>
          <Button onClick={() => exportClickTrack('mp3')} isDisabled={!selectedPiece}>
            Export MP3
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};