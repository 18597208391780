import React from 'react';
import { Box, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Text } from '@chakra-ui/react';

const TempoSlider = ({ tempoPercentage, onChange }) => {
  const tempoOptions = [25, 50, 75, 100];

  const handleChange = (value) => {
    const closestTempo = tempoOptions.reduce((prev, curr) => 
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
    onChange(closestTempo);
  };

  return (
    <Box>
      <Text mb={2}>Tempo: {tempoPercentage}%</Text>
      <Slider
        aria-label="tempo-slider"
        defaultValue={tempoPercentage}
        min={25}
        max={100}
        step={25}
        onChange={handleChange}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Box>
  );
};

export default TempoSlider;