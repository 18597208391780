import { useState, useEffect, useCallback, useRef } from 'react';

const useMetronome = (piece, startLabel) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [tempoPercentage, setTempoPercentage] = useState(100);
    const [volume, setVolume] = useState(0.5);
    const audioContextRef = useRef(null);
    const nextNoteTimeRef = useRef(0);
    const currentSectionRef = useRef(0);
    const currentBeatRef = useRef(0);
    const currentTempoRef = useRef(0);
    const finalTempoRef = useRef(0);
    const masterGainNodeRef = useRef(null);
    const workerRef = useRef(null);
  
    const updateVolume = useCallback(() => {
      if (masterGainNodeRef.current && audioContextRef.current) {
        masterGainNodeRef.current.gain.setValueAtTime(volume, audioContextRef.current.currentTime);
      }
    }, [volume]);

  const scheduleNote = useCallback((beatNumber, time) => {
    if (!audioContextRef.current || !masterGainNodeRef.current) return;

    const oscillator = audioContextRef.current.createOscillator();
    const gainNode = audioContextRef.current.createGain();

    oscillator.type = 'square';
    oscillator.frequency.setValueAtTime(beatNumber === 0 ? 440 : 220, time);

    gainNode.gain.setValueAtTime(0, time);
    gainNode.gain.linearRampToValueAtTime(1, time + 0.005);
    gainNode.gain.linearRampToValueAtTime(0, time + 0.05);

    oscillator.connect(gainNode);
    gainNode.connect(masterGainNodeRef.current);

    oscillator.start(time);
    oscillator.stop(time + 0.05);
  }, []);

  const getCurrentTempo = useCallback(() => {
    if (!piece) return finalTempoRef.current || 60;
    
    if (currentSectionRef.current >= piece.sections.length) {
      return finalTempoRef.current;
    }
  
    const section = piece.sections[currentSectionRef.current];
    let tempo;
    if (section.type === 'constant') {
      tempo = section.tempo;
    } else if (section.type === 'variable') {
      const progress = currentBeatRef.current / (section.duration * section.timeSignature.numerator);
      const startTempo = section.tempo;
      const endTempo = section.goal;
      tempo = startTempo + (endTempo - startTempo) * progress;
    }
    
    // Apply tempo percentage
    tempo = tempo * (tempoPercentage / 100);
    
    currentTempoRef.current = tempo;
    finalTempoRef.current = tempo;
    return tempo;
  }, [piece, tempoPercentage]);

  const setMetronomeTempoPercentage = useCallback((newTempoPercentage) => {
    setTempoPercentage(newTempoPercentage);
  }, []);


  const stopMetronome = useCallback(() => {
    if (workerRef.current) {
      workerRef.current.postMessage('stop');
    }
    setIsPlaying(false);
  }, []);

  const scheduler = useCallback(() => {
    if (!piece || !audioContextRef.current) return;

    const currentTime = audioContextRef.current.currentTime;

    while (nextNoteTimeRef.current < currentTime + 0.1) {
      if (currentSectionRef.current >= piece.sections.length) {
        stopMetronome();
        return;
      }

      const section = piece.sections[currentSectionRef.current];
      const beatsPerBar = section.timeSignature.numerator;
      const beatDuration = 60 / getCurrentTempo() * (4 / section.timeSignature.denominator);

      scheduleNote(currentBeatRef.current % beatsPerBar, nextNoteTimeRef.current);

      nextNoteTimeRef.current += beatDuration;
      currentBeatRef.current++;

      if (currentBeatRef.current >= section.duration * beatsPerBar) {
        currentSectionRef.current++;
        currentBeatRef.current = 0;
      }
    }

    const interval = (nextNoteTimeRef.current - audioContextRef.current.currentTime) * 1000;
    if (workerRef.current) {
      workerRef.current.postMessage({ interval: Math.max(interval, 0) });
    }
  }, [piece, scheduleNote, getCurrentTempo, stopMetronome]);

  const startMetronome = useCallback(() => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      masterGainNodeRef.current = audioContextRef.current.createGain();
      masterGainNodeRef.current.connect(audioContextRef.current.destination);
    } else {
      audioContextRef.current.resume();
    }
    updateVolume(); // Set initial volume
    nextNoteTimeRef.current = audioContextRef.current.currentTime;

    currentSectionRef.current = piece.sections.findIndex(section => section.label === startLabel);
    if (currentSectionRef.current === -1) currentSectionRef.current = 0;
    currentBeatRef.current = 0;

    getCurrentTempo();

    scheduler();
    
    if (workerRef.current) {
      workerRef.current.postMessage('start');
    }
    setIsPlaying(true);
  }, [piece, startLabel, getCurrentTempo, scheduler, updateVolume]);

  const toggleMetronome = useCallback(() => {
    if (isPlaying) {
      stopMetronome();
    } else {
      startMetronome();
    }
  }, [isPlaying, startMetronome, stopMetronome]);

  useEffect(() => {
    workerRef.current = new Worker('/metronomeWorker.js');
    workerRef.current.onmessage = function(e) {
      if (e.data === "tick") {
        scheduler();
      }
    };
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [scheduler]);

  useEffect(() => {
    if (isPlaying) {
      stopMetronome();
      startMetronome();
    }
  }, [piece, startLabel, isPlaying, stopMetronome, startMetronome]);

  const setMetronomeVolume = useCallback((newVolume) => {
    // setVolume(newVolume);
    if (masterGainNodeRef.current && audioContextRef.current) {
      masterGainNodeRef.current.gain.setValueAtTime(newVolume, audioContextRef.current.currentTime);
    }
  }, []);

  return { isPlaying, toggleMetronome, setMetronomeVolume, volume, setMetronomeTempoPercentage };
};

export default useMetronome;